export const BasketIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="218.51"
      height="162.17"
      viewBox="0 0 218.51 162.17"
      className={'h-full w-auto stroke-[1px]'}
      stroke="inherit"
    >
      <line
        x1="109.49"
        y1="34.45"
        x2="109.49"
        y2="161.76"
        fill="none"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <polygon
        points=".68 34.27 25.3 161.61 194.26 161.61 217.84 34.27 .68 34.27"
        fill="none"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="68.86"
        y1="34.45"
        x2="68.86"
        y2="161.76"
        fill="none"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="20.68"
        y1="137.94"
        x2="198.92"
        y2="137.94"
        fill="none"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="150.12"
        y1="34.45"
        x2="150.12"
        y2="161.76"
        fill="none"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="14.22"
        y1="102.35"
        x2="205.48"
        y2="102.35"
        fill="none"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="7.01"
        y1="66.75"
        x2="212.53"
        y2="66.75"
        fill="none"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <polyline
        points="150.23 34.02 150.23 .56 68.82 .56 68.82 34.02"
        fill="none"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="48.54"
        y1="34.45"
        x2="48.54"
        y2="161.76"
        fill="none"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="28.23"
        y1="33.77"
        x2="28.23"
        y2="161.08"
        fill="none"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="89.17"
        y1="33.77"
        x2="89.17"
        y2="161.08"
        fill="none"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="129.8"
        y1="33.77"
        x2="129.8"
        y2="161.08"
        fill="none"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="170.43"
        y1="33.77"
        x2="170.43"
        y2="161.08"
        fill="none"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="190.75"
        y1="33.77"
        x2="190.75"
        y2="161.08"
        fill="none"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
export const BasketIconMobile = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.81"
      height="20.55"
      viewBox="0 0 25.81 20.55"
      className={'h-full w-auto stroke-inherit fill-none stroke-2'}
      vectorEffect="non-scaling-stroke"
    >
      <polygon
        points=".84 4.72 3.58 19.85 22.36 19.85 24.98 4.72 .84 4.72"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="8.34"
        y1="4.72"
        x2="8.34"
        y2="19.85"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="17.48"
        y1="4.72"
        x2="17.48"
        y2="19.85"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="2.68"
        y1="14.89"
        x2="23.22"
        y2="14.89"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="1.73"
        y1="9.62"
        x2="24.13"
        y2="9.62"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <polyline
        points="17.48 4.72 17.48 .71 8.34 .71 8.34 4.72"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};
export const BasketIconThin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="219.09"
      height="162.65"
      viewBox="0 0 219.09 162.65"
      className={'relative w-full h-auto stroke-2 stroke-black fill-none'}
      vectorEffect="non-scaling-stroke"
    >
      <line
        x1="109.78"
        y1="34.69"
        x2="109.78"
        y2="162"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <polygon
        points=".97 34.51 25.59 161.85 194.55 161.85 218.13 34.51 .97 34.51"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="69.15"
        y1="34.69"
        x2="69.15"
        y2="162"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="20.97"
        y1="138.18"
        x2="199.21"
        y2="138.18"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="150.41"
        y1="34.69"
        x2="150.41"
        y2="162"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="14.51"
        y1="102.59"
        x2="205.77"
        y2="102.59"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="8.3"
        y1="66.99"
        x2="212.82"
        y2="66.99"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <polyline
        points="150.52 34.26 150.52 .8 69.11 .8 69.11 34.26"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="48.83"
        y1="34.69"
        x2="48.83"
        y2="162"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="28.52"
        y1="34.01"
        x2="28.52"
        y2="161.32"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="89.46"
        y1="34.01"
        x2="89.46"
        y2="161.32"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="130.09"
        y1="34.01"
        x2="130.09"
        y2="161.32"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="170.72"
        y1="34.01"
        x2="170.72"
        y2="161.32"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
      <line
        x1="191.04"
        y1="34.01"
        x2="191.04"
        y2="161.32"
        strokeMiterlimit="10"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};
